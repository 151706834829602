import React,{useEffect} from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import { connect } from "react-redux"
import {getPropertyAction} from '../../../redux/propertiesDucks'
import { getType, getOperations, getFakeAddres, getLocation } from "../../../helpers/helper.properties"
import { getCover } from "../../../helpers/helper.rendering"

const ClientSideOnlyLazy = React.lazy(() =>
  import("../../../components/Propiedad/main")
)
const ClientSideOnlyLazyAbout = React.lazy(() =>
  import('../../../components/Propiedad/about')
)

//Components
import Expand from '../../../components/Propiedad/expand'

const IndexPage = (props) => {

  const isSSR = typeof window === "undefined"

  const {id} = props
  const {dispatch} = props
  const {api_key} = props
  // const {serverData} = props

  useEffect(() => {
    if(api_key && id){
      dispatch(getPropertyAction(id))
    }
  },[api_key,id])

  return (
    <>
      <Layout>
            {/* <Seo 
                title={getType(serverData) + " en "  + getOperations(serverData)[0] + " - " + getFakeAddres(serverData)}
                meta={"Encontrá la propiedad que estabas buscando. " + getFakeAddres(serverData) + " en " + getOperations(serverData)[0] +  " en " +  getLocation(serverData)} 
                description={"Encontrá la propiedad que estabas buscando. " + getFakeAddres(serverData) + " en " + getOperations(serverData)[0] +  " en " +  getLocation(serverData)} 
                image={getCover(serverData.photos)?.original}
            /> */}
            <Seo 
                title={"Venta y Alquiler de Propiedades"}
                meta={"Encontrá la propiedad que estabas buscando."} 
                description={"Encontrá la propiedad que estabas buscando."} 
            />
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <ClientSideOnlyLazy id={id} />
                <ClientSideOnlyLazyAbout />
              </React.Suspense>
            )}
      </Layout>
    </>
  )
}

export default connect(state => ({
    api_key: state.settings.keys.tokko
}),null)(IndexPage);

// export async function getServerData(context) {
//     try {
//       const res = await fetch(`https://api-mh.mediahaus.com.ar/api/v1/tokko/properties/${context.params.id}?cache=true`,{headers: {"X-TOKKO-KEY": "4aeb6e6d5e8f5f041734390ce1a2a65238328ae9"}})
//       if (!res.ok) {
//         throw new Error(`Response failed`)
//       }
//       return {
//         props: await res.json(),
//       }
//     } catch (error) {
//       return {
//         status: 500,
//         headers: {},
//         props: {}
//       }
//     }
// }
  